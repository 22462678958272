<template>
  <div class="checkbox-group">
    <a-checkbox-group
        :defaultValue="defaultValue"
        @change="change"
      :options="rlist"
    />
  </div>
</template>

<script>
export default {
  name: 'MultipleEdit',
  props: {
    option: {
      required: true,
      type: Object,
      default () {
        return {
        }
      }
    }
  },
  data(){
    return{
      rlist:[],
      defaultValue:[],
    }
  },
  methods:{
    change(n){
      this.$emit('pushOn',n)
    }
  },
  created(){
      if (this.option?.research_answer?.the_key) {
        this.defaultValue = this.option?.research_answer.the_key.split(",").map(d=>+d)
      }
    this.rlist=this.option.research_question_option.map(d=>({
      label:d.the_value,
      value:d.the_key,
    }))
  }
}
</script>

<style scoped>
.checkbox-group{
  line-height: normal;
  padding: 6px 0;
}
</style>
