<template>
  <div class="">
    <Rate @change="change" :class="[rate.count===10?'isflex':'']" v-model="rate.value" :count="rate.count"/>
    <a v-if="rate.msg[rate.value-1]&&rate.count===5">
      <span>{{ rate.msg[rate.value-1] }}</span>
    </a>
    <div class="ten-start" v-if="rate.count===10">
      <a class="last-msg">
            <span v-if="rate.value!==0">
              {{ rate.msg[0] || "" }}
            </span>
      </a>
      <a class="last-msg">
            <span v-if="rate.value>=5">
              {{ rate.msg[1] || "" }}
            </span>
      </a>
      <a class="last-msg">
            <span v-if="rate.value>=8">
              {{ rate.msg[2] || "" }}
            </span></a>
    </div>
  </div>
</template>

<script>
import {Rate} from 'ant-design-vue'
import enums from "@/utils/enums";

export default {
  components: {Rate},
  props: {
    option: {
      required: true,
      type: Object,
      default() {
        return {
          count: 5,
          value: 2
        }
      }
    }
  },
  name: 'EditRating',
  data() {
    return {
      enums,
      value: 2,
      rate: {value: 0}
    }
  },
  created() {
    if (this.option?.research_answer?.the_key) {
      this.rate.value = +this.option?.research_answer.the_key
    }
    this.rate.msg = JSON.parse(this.option.prompt_json)
    this.rate.count = this.option.score_type === enums.RESEARCH_GRADE.TEN_GRADE.v ? 10 : 5
  },
  methods: {
    change(n){
      this.$emit('pushOn',n)
    }
  }
}
</script>

<style scoped lang="less">
/deep/ .ant-rate-star {
  margin-right: 10px;
}

/deep/ .ten-start {
  width: 400px;
  display: flex;
  justify-content: space-between;

  a {
    flex: 1;
  }

  .last-msg {

  }
}
.isflex{
    display: flex;
}
.checkbox-group{
  line-height: normal;
  padding: 6px 0;
}
</style>
