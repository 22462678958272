<template>
  <div class="checkbox-group">
    <div class="box-pleased-box" >
      <div class="box-pleased" @click="handelbox(1)">
        <iconpark-icon size="30" :name="boxPleased!==1?'feichangmanyi1':'feichangmanyi2'"></iconpark-icon>
        <p>非常满意</p>
      </div>
      <div class="box-pleased" @click="handelbox(2)">
        <iconpark-icon size="30" :name="boxPleased!==2?'manyi1':'manyi2'"></iconpark-icon>
        <p>满意</p>
      </div>
      <div class="box-pleased" @click="handelbox(3)">
        <iconpark-icon size="30" :name="boxPleased!==3?'yiban1':'yiban2'"></iconpark-icon>
        <p>一般</p>
      </div>
      <div class="box-pleased" @click="handelbox(4)">
        <iconpark-icon size="30" :name="boxPleased!==4?'bumanyi1':'bumanyi2'"></iconpark-icon>
        <p>不满意</p>
      </div>
      <div class="box-pleased" @click="handelbox(5)">
        <iconpark-icon size="30" :name="boxPleased!==5?'fennu1':'fennu2'"></iconpark-icon>
        <p>非常不满意</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    option: {
      required: true,
      type: Object,
      default () {
        return {
        }
      }
    }
  },
  name: "Cast",
  data(){
    return{
      boxPleased:null
    }
  },
  created(){
    if (this.option?.research_answer?.the_key) {
      this.boxPleased = +this.option?.research_answer.the_key
    }
  },
  methods:{
    handelbox (n) {
      this.boxPleased = n
      this.$emit('pushOn',n)
    },
  }
}
</script>

<style scoped lang="less">
.checkbox-group{
  line-height: normal;
  padding: 6px 0;
}
.box-pleased-box{
  p{
    line-height: 28px;
    margin-bottom: 0;
  }
  width: 110%;
  transform: translateX(-5%);
  display: flex;
  .box-pleased{
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    p{
      font-size: 12px;
    }
  }
}
</style>
