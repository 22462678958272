<template>
  <div class="checkbox-group">
    <a-textarea
        :defaultValue="defaultValue"
        @change="change"
        :auto-size="{ minRows: 3, maxRows: 10 }"/>
  </div>
</template>

<script>
export default {
  data() {
    return {
      defaultValue: null
    }
  },
  props: {
    option: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  created() {
    if (this.option?.research_answer?.the_key) {
      this.defaultValue = this.option?.research_answer.the_key
    }
  },
  methods: {
    change(e) {
      this.$emit('pushOn', e.target.value)
    }
  },
  name: 'AnswerEdit'
}
</script>

<style scoped>
.checkbox-group {
  line-height: normal;
  padding: 6px 0;
}
</style>
